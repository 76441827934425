<template>
   <v-row class="fill-height mt-16">
        <v-col cols="12" align="center">
            <span class="text-h4 font-weight-light grey--text text--darken-2">
                General Dashboard Home Page
            </span>
            <br>
            <span class="text-h4 grey--text text--darken-2">
                Coming Soon!
            </span>
        </v-col>
        <v-col cols="12" align="center">
            <v-img
                    width="50%"
                    src="@/assets/comingSoon.svg">
            </v-img>
        </v-col>
    </v-row>
</template>

<script>
  export default {
    name: 'generalHome'
  }
</script>