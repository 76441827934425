<template>
  <div>
    <v-row class="fill-height ma-2">
      <v-col cols="6" class="my-auto">
        <reports></reports>
      </v-col>
      <v-col cols="5">
        <v-row class="my-auto mx-auto">
          <v-col cols="6" align="center">
            <v-card width="100%">
              <v-card-title
                class="
                  justify-center
                  text-h3
                  primary--text
                  text--lighten-1
                  font-weight-light
                "
              >
                {{kioskCount.availCount}}/{{kioskCount.totalCount}}
              </v-card-title>
              <v-card-text
                class="d-flex justify-center grey--text text--darken-2"
              >
                Kiosks Online
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" align="center">
            <v-card width="100%">
              <v-card-title
                class="
                  justify-center
                  text-h3
                  primary--text
                  text--lighten-1
                  font-weight-light
                "
              >
                {{usersCount.availCount}}/{{usersCount.totalCount}}
              </v-card-title>
              <v-card-text
                class="d-flex justify-center grey--text text--darken-2"
              >
                Users Online
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" align="center">
            <v-card width="100%">
              <v-card-title
                class="
                  justify-center
                  text-h3
                  primary--text
                  text--lighten-1
                  font-weight-light
                "
              >
                NA
              </v-card-title>
              <v-card-text
                class="d-flex justify-center grey--text text--darken-2"
              >
                Questions Responded
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" align="center">
            <v-card width="100%">
              <v-card-title
                class="
                  justify-center
                  text-h3
                  primary--text
                  text--lighten-1
                  font-weight-light
                "
              >
                NA
              </v-card-title>
              <v-card-text
                class="d-flex justify-center grey--text text--darken-2"
              >
                Dial-In Sessions
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import reports from "@/components/dashboard/admin/kioskAnalytics/reports.vue";
import { mapGetters } from "vuex";

export default {
  name: "adminHome",
  components: {
    reports,
  },
  computed:{
    ...mapGetters(["allRegisteredUsers", "allRegisteredKiosks"]),
    usersCount(){
      let count = {
        availCount: 0,
        totalCount: 0
      };
      this.allRegisteredUsers.forEach(user => {
        count.totalCount = count.totalCount + 1
        if(user.data().onlineStatus === "Available"){
          // increase count here
          count.availCount = count.availCount + 1
        }
      })
      return count
    },
    kioskCount(){
      let count = {
        availCount: 0,
        totalCount: 0
      };
      this.allRegisteredKiosks.forEach(kiosk => {
        count.totalCount = count.totalCount + 1
        if(kiosk.data().onlineStatus === "Available"){
          // increase count here
          count.availCount = count.availCount + 1
        }
      })
      return count
    }
  }
};
</script>