<template>
    <adminHome v-if="(userProfile.data().userRole).toLowerCase() === 'admin' || (userProfile.data().userRole).toLowerCase() === 'super admin'"></adminHome>
    <generalHome v-else></generalHome>

</template>

<script>
    import adminHome from '@/views/admin/adminHome.vue';
    import generalHome from '@/views/general/generalHome.vue';
    import {mapGetters} from 'vuex';

  export default {
    name: 'home',
    components:{
      adminHome,
      generalHome
    },
    computed:{
      ...mapGetters(['userProfile'])
    }
  }
</script>